  <template>
    <div id="kt_header" ref="kt_header" class="header flex-column header-fixed">
      <div class="header-top">
        <div class="container">
          <div class="d-none d-lg-flex align-items-center mr-3">
            <!--begin::Logo-->
            <div class="mr-20">
              <router-link to="/">
                <img alt="Logo" :src="layoutConfig('self.logo.default')" class="max-h-35px" />
              </router-link>
            </div>
            <!--end::Logo-->
          </div>
          <KTTopbar></KTTopbar>
        </div>
      </div>
      <div class="header-bottom" style="height: 0px;">
        <div class="container">
          <div class="header-navs header-navs-left" id="kt_header_navs" ref="kt_header_navs">

            <b-tabs class="hide-tabs" :value.sync="tabIndex">
              <b-tab>
                <div class="tab-pane py-5 show active">
                  <!--begin::Menu-->
                  <div id="kt_header_menu" ref="kt_header_menu"
                    class="header-menu header-menu-mobile header-menu-layout-default">
                    <ul class="menu-nav">
                      <!-- <li class="d-lg-none menu-item">This text will only be visible under 768px</li> -->
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(0)"
                        @click="setClass(0)">
                        <router-link :to="{ name: 'dashboard' }" class="menu-link">
                          <span class="menu-text"> Dashboard</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(16)"
                        @click="setClass(16)">
                        <router-link :to="{ name: 'mldashboard' }" class="menu-link">
                          <span class="menu-text"> Project Pinskey</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(15)"
                        @click="setClass(15)">
                        <router-link :to="{ name: 'calendar' }" class="menu-link">
                          <span class="menu-text"> Calendar </span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(2)"
                        @click="setClass(2)">
                        <router-link :to="{ name: 'coaches' }" class="menu-link">
                          <span class="menu-text"> Coaches </span>
                        </router-link>
                      </li>
                      <!-- <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(3)"
                        @click="setClass(3)">
                        <router-link :to="{ name: 'subscribers' }" class="menu-link">
                          <span class="menu-text"> Members </span>
                        </router-link>
                      </li> -->
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(5)"
                        @click="setClass(5)">
                        <router-link :to="{ name: 'livestreamtest' }" class="menu-link">
                          <span class="menu-text"> Video Test </span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(6)"
                        @click="setClass(6)">
                        <router-link :to="{ name: 'livestream' }" class="menu-link">
                          <span class="menu-text"> Start Live Stream</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(12)"
                        @click="setClass(12)">
                        <router-link :to="{ name: 'bestpractices' }" class="menu-link">
                          <span class="menu-text"> Best Practices</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item"
                        :class="getClass(17)" @click="setClass(17)">
                        <router-link :to="{ name: 'livestreamingnow' }" class="menu-link">
                          <span class="menu-text"> Live Streaming Now</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item"
                        :class="getClass(18)" @click="setClass(18)" v-if="currentUser.isEmployee">
                        <router-link :to="{ name: 'livestreamingtest' }" class="menu-link">
                          <span class="menu-text"> Live Streaming Test</span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(7)"
                        @click="setClass(7)">
                        <router-link :to="{ name: 'personal' }" class="menu-link">
                          <span class="menu-text"> Contact Details </span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(8)"
                        @click="setClass(8)">
                        <router-link :to="{ name: 'password' }" class="menu-link">
                          <span class="menu-text"> Change Password </span>
                        </router-link>
                      </li>
                      <li aria-haspopup="true" data-menu-toggle="hover" class="d-lg-none menu-item" :class="getClass(9)"
                        @click="setClass(9)">
                        <a href="https://forms.gle/x7Yxyhtzuhnmn6iN9" class="menu-link" style="cursor: pointer"
                          aria-selected="false" target="_blank">
                          <span class="menu-text"> Report</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <!--end::Menu-->
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import { mapGetters } from "vuex";
  import KTTopbar from "@/view/layout/header/Topbar.vue";
  import KTLayoutHeader from "@/assets/js/layout/base/header.js";
  import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
  // import KTMenu from "@/view/layout/header/Menu.vue";

  export default {
    name: "KTHeader",
    data() {
      return {
        isActive: false,
        isExactActive: false,
      };
    },
    components: {
      KTTopbar,
      // KTMenu
    },
    mounted() {
      // Init Desktop & Mobile Headers
      KTLayoutHeader.init(
        this.$refs["kt_header"],
        this.$refs["kt_header_mobile"]
      );

      // Init Header Menu
      KTLayoutHeaderMenu.init(
        this.$refs["kt_header_menu"],
        this.$refs["kt_header_navs"]
      );

      const headerRef = this.$refs["kt_header"];

      headerRef.querySelectorAll("a[class='menu-link']").forEach(item => {
        item.addEventListener("click", () => {
          KTLayoutHeaderMenu.getOffcanvas().hide();
        });
      });
    },
    computed: {
      ...mapGetters(["layoutConfig", "getClasses", "tabIndex", "activeTab", "currentUser"]),

      /**
       * Check if the header menu is enabled
       * @returns {boolean}
       */
      headerMenuEnabled() {
        return !!this.layoutConfig("header.menu.self.display");
      },

      /**
       * Get extra classes for header based on the options
       * @returns {null|*}
       */
      headerClasses() {
        const classes = this.getClasses("header");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },

      /**
       * Get extra classes for header menu based on the options
       * @returns {null|*}
       */
      headerMenuClasses() {
        const classes = this.getClasses("header_menu");
        if (typeof classes !== "undefined") {
          return classes.join(" ");
        }
        return null;
      },

      /**
       * Check if header container is fluid
       */
      widthFluid() {
        return this.layoutConfig("header.self.width") === "fluid";
      }
    },
    watch: {
      tabIndex(newValue) {
        const elemHomeDesk = this.$refs.homeDesktopBtn;
        const elemHomeMobile = this.$refs.homeMobileBtn;
        const elemAdminDesk = this.$refs.adminDesktopBtn;
        const elemAdminMobile = this.$refs.adminMobileBtn;
        const elemCommunityDesk = this.$refs.communityDesktopBtn;
        const elemCommunityMobile = this.$refs.communityMobileBtn;
        const elemLivestreamDesk = this.$refs.livestreamDesktopBtn;
        const elemLivestreamMobile = this.$refs.livestreamMobileBtn;
        const elemSettingsDesk = this.$refs.settingsDesktopBtn;
        const elemSettingsMobile = this.$refs.settingsMobileBtn;

        switch (newValue) {
          case 0:
            elemHomeDesk.click();
            elemHomeMobile.click();
            break;
          case 1:
            elemAdminDesk.click();
            elemAdminMobile.click();
            break;
          case 2:
            elemCommunityDesk.click();
            elemCommunityMobile.click();
            break;
          case 3:
            elemLivestreamDesk.click();
            elemLivestreamMobile.click();
            break;
          case 4:
            elemSettingsDesk.click();
            elemSettingsMobile.click();
            break;
        }
      }
    },
    methods: {
      getClass(tabNumber) {
        return this.activeTab == tabNumber ? "menu-item-active" : "";
      },
      setClass(tabNumber) {
        this.$store.commit("CHANGE_ACTIVETAB", { activeTab: tabNumber })
      },
      /**
       * Set current active on click
       * @param event
       */
      setActiveTab(event) {
        let target = event.target;
        if (!event.target.classList.contains("nav-link")) {
          target = event.target.closest(".nav-link");
        }

        const tab = target.closest('[role="tablist"]');
        const links = tab.querySelectorAll(".nav-link");
        // remove active tab links
        for (let i = 0; i < links.length; i++) {
          links[i].classList.remove("active");
        }

        // set clicked tab index to bootstrap tab
        let tabIndex = parseInt(target.getAttribute("data-tab"));
        this.$store.commit("CHANGE_TABINDEX", { tabIndex: tabIndex });

        // set current active tab
        target.classList.add("active");
      }
    }
  };
  </script>
